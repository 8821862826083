import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import Header from "../../../components/header/Header";
import { Text } from "../../../components/elements";
import HeadingAndImageSection from "../../../components/article/HeadingAndImageSection";
import { getLocalDate } from "../../../utils/dateUtilities";
import { getArticleIdForLang, getContentForArticle } from "./content";
import { useAppSelector } from "../../../behavior/hooks";
import { PageRoute } from "../../../constants";

const AdviceBeforeInterviewArticle = () => {
	const { lang } = useParams();
	const navigate = useNavigate();
	const { isLogged } = useAppSelector((state) => state.session);
	const content = getContentForArticle(lang || "");

	useEffect(() => {
		if (isLogged) {
			navigate(`${PageRoute.LEARN_WITH_HUBERT_ARTICLE}/${getArticleIdForLang(lang || "")}`);
		}
	}, [isLogged]);

	return (
		<div className="public-article-wrapper">
			<Header type="PublicArticle" />
			<div className="article-page-wrapper">
				<HeadingAndImageSection
					tag={content.primaryTag}
					headingText={content.title}
					image={content.featureImage}
					author={content.primaryAuthor}
					datePosted={getLocalDate(new Date(content.publishedOn))}
				/>
				<div className="article-content-section">
					<Text block html>
						{DOMPurify.sanitize(content.html ?? "")}
					</Text>
				</div>
			</div>
		</div>
	);
};

export default AdviceBeforeInterviewArticle;
