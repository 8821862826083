import { useLazyQuery, useMutation } from "@apollo/client";
import { SEND_BENEFITS_EMAIL } from "behavior/mutations/invitation.mutation";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { VERIFY_INVITATION } from "../../behavior/queries/invitation.query";
import Header from "../../components/header/Header";
import CompletedAlert from "../../components/interview/CompletedAlert";
import { PageState, TPageState } from "../../constants";
import { Invitation } from "../../types/invitation";
import { REGISTRATION_INTERVIEW_COMPLETED } from "../../utils/constants/heapIds";
import InviteEmailSignUp from "../registration/InviteEmailSignUp";
import "./InterviewCompletedPageInviteEmail.scss";

const InterviewCompletedPageInviteEmail: FC = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const params = new URLSearchParams(location.search);
	const [verifyInvitation] = useLazyQuery(VERIFY_INVITATION);
	const [sendBenefitsEmail] = useMutation(SEND_BENEFITS_EMAIL);
	const [invitation, setInvitation] = useState<Invitation>();
	const [pageState, setPageState] = useState<TPageState>(PageState.INVITATION_POST_INTERVIEW_SIGN_UP);

	const verify = async (token: string): Promise<void> => {
		try {
			const response = await verifyInvitation({
				variables: {
					token,
				},
				fetchPolicy: "no-cache",
			});

			if (!response) {
				toast.error(t("AppMessages.server-error"));
				return;
			}

			const type = response.data.invitation.__typename;
			if (type !== "Invitation") {
				toast.error(t(`ProcessInvitation.${type}`));
				navigate("/", { replace: true });
				return;
			}

			const invitationData = response.data.invitation as Invitation;
			if (invitationData.interviewCompleted) {
				await sendBenefitsEmail({ variables: { 
					token, 
				} });
				setInvitation(invitationData);
			} else {
				toast.error(
					t("InterviewCompletedPageInviteEmail.InterviewNotCompleted")
				);
				navigate("/", { replace: true });
			}
		} catch (e: any) {
			toast.error(t(e.message));
		}
	};

	useEffect(() => {
		const stateParam = params.get("state");
		if (stateParam) {
			const state = stateParam as TPageState;
			setPageState(state);
		}

		const token = params.get("token");
		if (token) {
			verify(token);
		} else {
			// navigate("/", { replace: true });
		}
	}, []);

	return (
		<>
			<Header />
			<div className="interview-completed-invite-email-wrapper">
				<CompletedAlert />
				<InviteEmailSignUp
					interviewCompleted
					invitation={invitation}
					state={PageState.INTERVIEW_COMPLETED}
					heapIds={REGISTRATION_INTERVIEW_COMPLETED}
				/>
			</div>
		</>
	);
};

export default InterviewCompletedPageInviteEmail;
