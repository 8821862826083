import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTexts from './locales/en-EU.json';
import swedishTexts from './locales/sv-SE.json';

i18n.use(initReactI18next).init({
  resources: {
    'en-EU': {
      translation: englishTexts,
    },
    'sv-SE': {
      translation: swedishTexts,
    },
  },
  lng: 'en-EU',
  fallbackLng: 'en-EU',
  interpolation: {
    escapeValue: false,
  },
});
