/* eslint-disable no-debugger */
import { useLazyQuery } from "@apollo/client";
import { useAppDispatch } from "behavior/hooks";
import { GET_VIEWER_INFORMATION } from "behavior/queries/viewer.query";
import { resetSession } from "behavior/reducers/sessionSlice";
import { useMyHubertSession } from "pages/instantJobApply/useMyHubertSession";
import {
	ComponentProps,
	useCallback,
	useEffect,
	useState
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Viewer } from "types/viewer";
import "./TransitionPage.scss";

export interface TransitionPageProps extends ComponentProps<"div"> {
	title?: string;
	subtitle?: string;
}

const TransitionPage = (props: TransitionPageProps) => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { logoutUser } = useMyHubertSession();
	const [transitionPageData, setTransitionPageData] =
		useState<TransitionPageProps>({
			title: props.title ?? t("TransitionPage.loading-title"),
			subtitle: props.subtitle ?? t("TransitionPage.loading-subtitle"),
		});
	const [getViewerInformation] = useLazyQuery<{ viewer: Viewer }>(
		GET_VIEWER_INFORMATION
	);
	interface TransitionState {
		redirectUrl: string;
		logout: boolean;
		emailVerified: boolean;
	}

	const getCurrentViewer = useCallback(async () => {
		try {
			if (location.state) {
				const { redirectUrl, logout } = location.state as TransitionState;
				if (logout) {
					setTransitionPageData({
						title: t("TransitionPage.logout-title"),
						subtitle: t("TransitionPage.logout-subtitle"),
					});
					await logoutUser();
					dispatch(resetSession());
				} else {
					await getViewerInformation();
				}

				const currentUrl = location.pathname + location.search;
				if (currentUrl !== redirectUrl) {
					navigate(redirectUrl, { replace: true, state: location.state });
				}
			}
		} catch (e: any) {
			toast.error(t(e.message));
		}
	}, [dispatch, getViewerInformation, location.state, navigate, t]);

	useEffect(() => {
		getCurrentViewer();
	}, [getCurrentViewer]);

	return (
		<section className="loader-container">
			<div className="logo">
				<div className="hubert-left">
					<div className="inner"></div>
				</div>
				<div className="hubert-right "></div>
			</div>
			<div>
				<p className="text-sm md:text-base">My Hubert</p>
				<h3 className="text-lg font-medium md:text-3xl">
					{transitionPageData.subtitle}
				</h3>
			</div>
		</section>
	);
};

export default TransitionPage;
