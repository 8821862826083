import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../behavior/hooks";
import { setSession } from "../../behavior/reducers/sessionSlice";
import { PageRoute } from "../../constants";

const useHydrateUserSession = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { isLogged } = useAppSelector((state) => state.session);

	useEffect(() => {
		function skipSessionCheck() {
			const skipRoutes = [
				PageRoute.GOOGLE_CALLBACK,
				PageRoute.FACEBOOK_CALLBACK,
				PageRoute.INVITATION_VERIFICATION,
				`${PageRoute.INSTANT_APPLY.INDEX}/${PageRoute.INSTANT_APPLY.POST_INTERVIEW}`,
				`${PageRoute.INSTANT_APPLY.INDEX}/${PageRoute.INSTANT_APPLY.PRE_INTERVIEW}`,
				PageRoute.DEMO,
				PageRoute.IMPERSONATION,
			];
			return skipRoutes.find((x) => location.pathname.startsWith(x));
		}

		if (skipSessionCheck()) return;

		const token = localStorage.getItem("candidate-hub-token");
		const refreshToken = localStorage.getItem("candidate-hub-refresh-token");
		if (token && refreshToken && !isLogged) {
			const payload = {
				access_token: token,
				refresh_token: refreshToken,
				token_type: "Bearer",
				expires_in: 3600,
				action: "",
			};
			dispatch(setSession(payload));

			navigate(PageRoute.TRANSITION_PAGE, {
				state: { redirectUrl: `${location.pathname}${location.search}` },
			});
			return;
		}
	}, [dispatch, isLogged, location.pathname, location.search, navigate]);
};

export default useHydrateUserSession;
