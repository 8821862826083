import { isTokenExpiring } from "behavior/security/auth.service";
import jwt_decode from "jwt-decode";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { generateInterviewUrlRaw } from "utils/urlUtility";
import Header from "../../../components/header/Header";
import { ApplicationLanguage, PageRoute, PageState } from "../../../constants";
import { Invitation, InvitationTokenPayload } from "../../../types/invitation";
import { LOGIN_INVITATION_EMAIL } from "../../../utils/constants/heapIds";
import { getCookie } from "../../../utils/cookieHandlers";
import InviteEmailSignIn from "./InviteEmailSignIn";
import "./LoginInviteEmail.scss";

const LoginInviteEmail = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const invitation = useMemo(() => {
		const locationState = location.state as { invitation: Invitation };
		return locationState.invitation;
	}, [location.state]);

	const redirectToInterview = useCallback(
		(accessToken?: string) => {
			const params = new URLSearchParams(location.search);
			const tokenValue = params.get("token");
			if (tokenValue) {
				const jwt = jwt_decode<InvitationTokenPayload>(tokenValue);
				const interviewStartUrl = generateInterviewUrlRaw(
					jwt.interviewId,
					`${PageRoute.DASHBOARD}?state=${PageState.INTERVIEW_COMPLETED}`,
					accessToken
				);
				navigate("/pre-interview", {
					replace: true,
					state: {
						interviewUrl: interviewStartUrl,
						invitation,
					},
				});
				return;
			}
			toast.error(t("AppMessages.invalid-invitation"));
		},
		[invitation, navigate, t, location.search]
	);

	useEffect(() => {
		const token = localStorage.getItem("candidate-hub-token");
		const params = new URLSearchParams(location.search);
		if (location.state) {
			if (token && invitation) {
				if (isTokenExpiring(token)) {
					const currentUrl = `${
						PageRoute.PRE_INTERVIEW_LOGIN
					}?${params.toString()}`;
					navigate(PageRoute.TRANSITION_PAGE, {
						state: { ...location.state, redirectUrl: currentUrl },
					});
				} else {
					redirectToInterview(token);
				}
				return;
			}
		} else {
			navigate("/", { replace: true });
		}
	}, [
		invitation,
		location.state,
		navigate,
		redirectToInterview,
		location.search,
	]);

	const getInterviewLink = useCallback(async () => {
		const params = new URLSearchParams(location.search);
		const tokenValue = params.get("token");
		if (tokenValue) {
			const jwt = jwt_decode<InvitationTokenPayload>(tokenValue);
			const redirectUrl = new URL(
				PageRoute.POST_INTERVIEW_LOGIN,
				window.location.origin
			);

			redirectUrl.searchParams.append("email", jwt.email);
			redirectUrl.searchParams.append("token", tokenValue);
			redirectUrl.searchParams.append(
				"state",
				PageState.INVITATION_POST_INTERVIEW_SIGN_IN
			);

			const interviewUrl = process.env.REACT_APP_INTERVIEW_URL;
			return `${interviewUrl}/${
				jwt.interviewId
			}?redirectUrl=${encodeURIComponent(redirectUrl.toString())}`;
		}
		return "/";
	}, [location.search]);

	const skipToPreInterview = async () => {
		if (invitation) {
			const language: string =
				getCookie("preferred-language") ||
				getCookie("language") ||
				ApplicationLanguage.EN_EU;
			const interviewUrl = await getInterviewLink();
			navigate("/pre-interview", {
				replace: true,
				state: { interviewUrl, invitation },
			});
			return;
		} else {
			toast.info("Invalid Invitation.");
		}
	};

	return (
		<>
			<Header type="SignInInviteEmail" interviewRedirect={skipToPreInterview} />
			<InviteEmailSignIn
				heapIds={LOGIN_INVITATION_EMAIL}
				invitation={invitation}
				state={PageState.INVITATION_SIGN_IN}
				postLoginEvent={redirectToInterview}
			/>
		</>
	);
};

export default LoginInviteEmail;
