import VerifyEmail from "helpers/EmailVerification";
import FacebookCallback from "helpers/facebook/FacebookCallback";
import GoogleCallback from "helpers/google/GoogleCallback";
import CenteredLayout from "layouts/CenteredLayout";
import JobLandingPage from "pages/Demo/JobLandingPage";
import ErrorPage from "pages/ErrorPage";
import Impersonation from "pages/impersonation/Impersonation";
import {
	AdviceBeforeInterviewArticle,
	Article,
	Dashboard,
	ForgotPassword,
	HelpCenter,
	Interview,
	InterviewCompletedPageInviteEmail,
	LearnWithHubert,
	Login,
	LoginInviteEmail,
	LoginInviteEmailInterviewCompleted,
	PreInterviewPage,
	Registration,
	RegistrationInviteEmail,
	UnsubscribeFromEmails,
	YourInterviews,
	YourProfile,
} from "pages/index";
import PostInstantApply from "pages/instantJobApply/PostInstantApply";
import PreInstantApply from "pages/instantJobApply/PreInstantApply";
import ProcessInvitation from "pages/invitation/ProcessInvitation";
import RealTimeScreeningLogin from "pages/login/RealTimeScreening/Login";
import RealTimeScreeningPostInterviewLogin from "pages/login/RealTimeScreening/PostInterviewLogin";
import RealTimeScreeningPostInterviewRegister from "pages/login/RealTimeScreening/PostInterviewRegister";
import TransitionPage from "pages/transition/TransitionPage";
import { useEffect } from "react";
import { AlertCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "utils/ScrollToTop";
import { getCookie } from "utils/cookieHandlers";
import { useHydrateUserSession } from "utils/customHooks";
import ProtectedRoute from "./ProtectedRoute";
import { ApplicationLanguage, PageRoute } from "./constants";

const App = () => {
	const { i18n } = useTranslation();
	useHydrateUserSession();

	// Set app language from cookie, otherwise from browser language
	useEffect(() => {
		const languageCookie = getCookie("language");
		const preferredLanguageCookie = getCookie("preferred-language");

		if (languageCookie) {
			i18n.changeLanguage(languageCookie);
		} else if (preferredLanguageCookie) {
			i18n.changeLanguage(preferredLanguageCookie);
		} else if (navigator.languages) {
			// Check if languages is supported
			const supportedLanguages = ["en", "sv"];

			const userLanguages = navigator.languages.map((lang) => lang.slice(0, 2));
			const language = userLanguages.find((lang) =>
				supportedLanguages.includes(lang)
			);

			switch (language) {
				case "en":
					i18n.changeLanguage(ApplicationLanguage.EN_EU);
					break;
				case "sv":
					i18n.changeLanguage(ApplicationLanguage.SV_SE);
					break;
				default:
					break;
			}
		}
	}, [i18n]);

	return (
		<>
			<ToastContainer />
			<ScrollToTop>
				<Routes>
					<Route element={<CenteredLayout />}>
						<Route path={PageRoute.ROOT} element={<Login />} />
						<Route path={PageRoute.REGISTRATION} element={<Registration />} />
						<Route
							path={PageRoute.PRE_INTERVIEW_REGISTRATION}
							element={<RegistrationInviteEmail />}
						/>
						<Route
							path={PageRoute.POST_INTERVIEW_REGISTRATION}
							element={<InterviewCompletedPageInviteEmail />}
						/>
						<Route
							path={PageRoute.PRE_INTERVIEW_LOGIN}
							element={<LoginInviteEmail />}
						/>
						<Route
							path={PageRoute.POST_INTERVIEW_LOGIN}
							element={<LoginInviteEmailInterviewCompleted />}
						/>
						<Route
							path={PageRoute.PRE_INTERVIEW}
							element={<PreInterviewPage />}
						/>
						<Route
							path={PageRoute.GOOGLE_CALLBACK}
							element={<GoogleCallback />}
						/>
						<Route
							path={PageRoute.FACEBOOK_CALLBACK}
							element={<FacebookCallback />}
						/>
						<Route
							path={PageRoute.EMAIL_VERIFICATION_CALLBACK}
							element={<VerifyEmail />}
						/>
						<Route
							path={PageRoute.INVITATION_VERIFICATION}
							element={<ProcessInvitation />}
						/>
						<Route path={PageRoute.INSTANT_APPLY.INDEX}>
							<Route
								path={PageRoute.INSTANT_APPLY.POST_INTERVIEW}
								element={<PostInstantApply />}
							/>
							<Route
								path={PageRoute.INSTANT_APPLY.PRE_INTERVIEW}
								element={<PreInstantApply />}
							/>
							<Route
								path={PageRoute.INSTANT_APPLY.LOGIN}
								element={<RealTimeScreeningLogin />}
							/>
							<Route
								path={PageRoute.INSTANT_APPLY.POST_INTERVIEW_SIGNIN}
								element={<RealTimeScreeningPostInterviewLogin />}
							/>
							<Route
								path={PageRoute.INSTANT_APPLY.POST_INTERVIEW_SIGNUP}
								element={<RealTimeScreeningPostInterviewRegister />}
							/>
						</Route>
						<Route
							path={PageRoute.PASSWORD_RESET}
							element={<ForgotPassword />}
						/>
						<Route
							path={`${PageRoute.INTERVIEW_ADVICE}/:lang`}
							element={<AdviceBeforeInterviewArticle />}
						/>
						<Route
							path={PageRoute.TRANSITION_PAGE}
							element={<TransitionPage />}
						/>
						<Route path=""></Route>

						<Route element={<ProtectedRoute />}>
							<Route path={PageRoute.DASHBOARD} element={<Dashboard />} />
							<Route path={PageRoute.INTERVIEWS} element={<YourInterviews />} />
							<Route
								path={`${PageRoute.INTERVIEWS}/:id`}
								element={<Interview />}
							/>
							<Route path={PageRoute.PROFILE} element={<YourProfile />} />
							<Route
								path={PageRoute.LEARN_WITH_HUBERT}
								element={<LearnWithHubert />}
							/>
							<Route path={PageRoute.HELP_CENTER} element={<HelpCenter />} />
							<Route
								path={`${PageRoute.LEARN_WITH_HUBERT_ARTICLE}/:id`}
								element={<Article />}
							/>
							R
						</Route>
						<Route
							path={PageRoute.UNSUBSCRIBE_EMAIL}
							element={<UnsubscribeFromEmails />}
						/>
						<Route path={PageRoute.IMPERSONATION} element={<Impersonation />} />
						<Route
							path="*"
							element={
								<ErrorPage
									Icon={AlertCircle}
									title="Are you sure you were looking for this ?"
									description="The page you are looking for does not exist."
									hideNextSteps={false}
								/>
							}
						/>
						<Route path="/demo/:demoId/">
							<Route path="jobs/:jobId" element={<JobLandingPage />} />
						</Route>
					</Route>
				</Routes>
			</ScrollToTop>
		</>
	);
};

export default App;
